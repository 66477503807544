
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	.index &, .enIndex &{
		margin-bottom:rem(50px);
	}
	@include breakpoint(large) {
		padding-top: rem(157px);
		.index &, .enIndex &{
			margin-bottom:rem(109px);
		}
	}
	@include breakpoint(giant) {
		padding-top: rem(173px);
	}
	@include breakpoint(huge) {
		padding-top: rem(200px);
	}
	.clientCont{
		width:100%; 
		padding:rem(17px) 0 rem(22px);
		background:$light;
		box-shadow:0 rem(4px) rem(4px) rem(-4px) rgba(0,0,0,0.11);
		@include breakpoint(large) {
			position:absolute;
			top:rem(70px);
			z-index: 10;  
		}
		.index &, .enIndex &{
			box-shadow:none; 
		}
		.fixed &{
			box-shadow:0 rem(4px) rem(4px) rem(-4px) rgba(0,0,0,0.11);
			@include breakpoint(large) {
				position:fixed; 
			}
		}
		.brandCol{
			padding:rem(70px) rem($baseGap) rem(20px) rem($baseGap); 
			text-align:center; 
			@include breakpoint(large) {
				padding:0 0 0 rem($baseGap); 
				text-align:left; 
			}
			@include breakpoint(huge) {
				padding:0 rem($baseGap);  
			}
			.branding {
				display: inline-block;
			}
		}
		
		.infoCol{
			display:none; 
			@include breakpoint(large) {
				display:block; 
			}
			.infoCont{
				display:flex;
				justify-content:flex-end; 
				align-items:center; 
				.addressCont{
					margin-right:rem(44px); 
					span{
						color:$secondary; 
					}
				}
			}
		}

	}
	.claimCont{
		background:image-url('layout/tiny-bgClaim.jpg')no-repeat center center/cover;
		padding:rem(70px) 0 rem(70px); 
		@include breakpoint(tiny) {
			background:image-url('layout/medium-bgClaim.jpg')no-repeat center center/cover;
		}
		@include breakpoint(large) {
			padding:rem(250px) 0 rem(70px); 
			background:image-url('layout/large-bgClaim.jpg')no-repeat center center/cover;
		}
		@include breakpoint(large) {
			background:image-url('layout/bgClaim.jpg')no-repeat center center/cover;
		}
		@include breakpoint(giant) {
			padding:rem(286px) 0 rem(107px); 
		}
		blockquote{
			display:block;
			background:$light;
			padding:rem(50px) rem(40px); 
			position:relative;
			text-align:center;
			@include breakpoint(large) {
				display:inline-block;
				text-align:left; 
				padding:rem(36px) rem(57px) rem(48px) rem(40px);
			}
			.iconCont{
				width:rem(36px);
				height:rem(36px);
				background:$secondary;
				bottom:0;
				right:0; 
				position:absolute; 
				&:before{
					content:'+';
					top:50%;
					left:50%; 
					transform:translate(-50%, -50%);
					position:absolute; 
					color:$light;
					font-size:rem(24px);
					font-weight:300; 
					.javascript &{
						transition: all 0.3s ease-in-out;
					}
				}
				&:hover, &:focus, &:active{
					background:$medium;
					&:before{
						color:$primary;
					}
				}
			}
			p{
				font-size:rem(18px); 
				letter-spacing: 0.06em; 
				strong{
					display:block;
					font-size:rem(22px); 
    				line-height:rem(28px); 
					color:$dark; 
					margin-bottom:rem(10px);
					letter-spacing:normal; 
					 @include breakpoint(tiny) {
				      font-size:rem(24px); 
				      line-height:rem(30px);  
				    }
				    @include breakpoint(small) {
				      font-size:rem(26px); 
				      line-height:rem(32px);  
				    }
				    @include breakpoint(medium) {
				      font-size:rem(30px); 
				      line-height:rem(36px);  
				    }
				    @include breakpoint(large) {
				      font-size:rem(38px); 
				      line-height:rem(42px);  
				    }
				    @include breakpoint(giant) {
				      font-size:rem(41px); 
					  line-height:rem(51px);  
				    }
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding:rem(50px) 0 rem(26px); 
	@include breakpoint(large) {
		padding:rem(95px) 0 rem(71px); 
	}
	.index &, .enIndex &{
		padding:0; 
	}
	.section{ 
		margin-bottom:rem(26px); 
		@include breakpoint(large) {
		   margin-bottom:rem(94px);
		}
		&.teaserSection{
			background:$secondary;
			@include responsiveImage('layout/bgTeaser.jpg', (large), true);
			padding:rem(50px) 0 rem(26px); 
			margin-bottom:rem(50px);
			@include breakpoint(large) {
				margin-bottom:rem(118px); 
				padding:rem(96px) 0 rem(108px); 
			}
			&.lazyLoaded {
				background-size:cover;
				background-position: center center;
				background-repeat: no-repeat; 
			}
			.h1{
				color:$light; 
				span{
					color:$light;  	
				}
			}
			.col{ 
				margin-bottom:rem(24px); 
				&:nth-child(1){
					margin-bottom:0; 
				}
			}
			.linkSection{
				display:block;
				height:100%;
				background:$light; 
				color:$primary;
				text-decoration:none; 
				padding:rem(30px) rem(20px); 
				text-align:center;
				@include breakpoint(tiny) {
					padding:rem(75px) rem(43px) rem(70px) rem(43px); 
				}
				@include breakpoint(full) {
					text-align:left; 
				}
				strong{
					font-size:rem(20px);
					display:block; 
					margin-bottom:rem(20px); 
					@include breakpoint(tiny) {
						margin-bottom:rem(36px); 
						font-size:rem(24px);
					}
				}
				p{
					margin-bottom:rem(40px); 
				}
				.btn{
					margin-bottom:0; 
				} 
			}
		}
		.galleryCol{
			position:relative;
			padding:rem(50px) rem($baseGap) 0 rem($baseGap);
			margin-top:rem(26px); 
			@include breakpoint(large) {
				padding:rem(90px) rem($baseGap) 0 rem($baseGap);
				margin-top:rem(66px); 
			} 
			&:before{
				content:'';
				position:absolute;
				left:rem(18px);
				right:rem(18px);
				top:0;
				background:$medium;
				height:1px;
			}
		}
	}
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.mapCont{
		height:rem(290px);
		@include breakpoint(small) {
			height:rem(390px);
		}
		@include breakpoint(large) {
			height:rem(490px);
		}
		iframe{
			height:100%; 
		}
	}
	.clientCont{
		position:relative;
		width:100%; 
		padding:rem(50px) 0;
		text-align:center; 
		@include breakpoint(medium) {
			text-align:left; 
		}
		@include breakpoint(large) {
			padding:rem(72px) 0;
		}
		.addressCont{
			
			margin-bottom:rem(20px); 
			@include breakpoint(large) {
				padding-right:0; 
			}
			@include breakpoint(fullx) {
				margin-bottom:0; 
			}
			p{
				strong{
					span{
						color:$secondary; 
					}
				}
			}
		}
		.fragenCol{
			position:relative;
			padding:rem(30px) rem($baseGap) 0 rem($baseGap);
			margin-top:rem(30px);
			border-top: 1px solid #d6d6d6;
			@include breakpoint(medium) {
				padding:0 rem($baseGap) 0 rem(65px); 
				margin-top:0; 
				border-top:0; 
			} 
			@include breakpoint(large) {
				padding:0 rem($baseGap) 0 rem(95px); 
			}
			&:before{
				@include breakpoint(medium) {
					content:'';
					position:absolute;
					left:rem(30px); 
					top:0;
					height:100%;
					width:1px;
					background:#d6d6d6;
				}
				@include breakpoint(fullx) {
					height:rem(52px);
				}
			} 
			.fragenCont{
				@include breakpoint(fullx) {
					display:flex;
					justify-content:space-between;
				}
				.textCont{
					margin-bottom:rem(20px); 
					@include breakpoint(fullx) {
						margin-bottom:0; 
					}
					strong{
						font-size:rem(20px); 
					}
				}
				.btnCont{
					.btn{
						margin-bottom:0; 
					}
				}
			}	
		}
	}
}