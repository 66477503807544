* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
  background:$dark;
  color:$light;
}
::-moz-selection {
    background:$dark;
    color:$light;
}
strong, b{
  font-weight:700; 
}
// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $primary;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}
.preload *{
  transform:none !important; 
}
body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  line-height:130%;
  font-weight:300;
  color:$dark; 
  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
  font-size: rem(24px);
  line-height: rem(30px);
  font-weight:700;
    @include breakpoint(tiny) {
      font-size:rem(28px); 
      line-height:rem(34px);  
    }
    @include breakpoint(small) {
      font-size:rem(30px); 
      line-height:rem(30px);  
    }
    @include breakpoint(medium) {
      font-size:rem(34px); 
      line-height:rem(40px);  
    }
    @include breakpoint(large) {
      font-size:rem(42px); 
      line-height:rem(52px); 
      margin-bottom:rem(63px) !important; 
    }
    @include breakpoint(giant) {
      font-size: rem($h1Size);
      line-height: rem(61px); 
    }
  span{
    display:block; 
    font-weight:300;
    font-size:rem(20px);
    line-height:rem(30px);
    color:$primary;  
    letter-spacing: 0.06em; 
    @include breakpoint(large) {
      font-size:rem(26px);
      line-height:rem(37px);
    }
  }
}

h2,
.h2 {
  font-size: rem(22px);
  line-height:rem(28px);
  letter-spacing: 0.06em; 
  color:$primary;  
  @include breakpoint(large) {
    font-size: rem($h2Size);
    line-height:rem(37px);
  } 
  .index &, .enIndex  &{
    font-size:rem(22px); 
    line-height:rem(28px);  
    font-weight:700; 
    letter-spacing:normal;
    color:$dark;   
    @include breakpoint(tiny) {
      font-size:rem(24px); 
      line-height:rem(30px);  
    }
    @include breakpoint(small) {
      font-size:rem(26px); 
      line-height:rem(30px);  
    }
    @include breakpoint(medium) {
      font-size:rem(30px); 
      line-height:rem(36px);  
    }
    @include breakpoint(large) {
      font-size:rem(38px); 
      line-height:rem(44px);  
      margin-bottom:rem(60px) !important; 
    }
    @include breakpoint(giant) {
      font-size:rem(42px);  
      line-height:rem(47px); 
    }
  }
  .standort &, .directions &{ 
    margin-bottom:0; 
  }
  span{
    display:block;
    font-weight:300;
    font-size:rem(19px);
    line-height:rem(25px);
    color:$primary;  
    letter-spacing: 0.06em; 
    @include breakpoint(large) {
      font-size:rem(23px);
      line-height:rem(37px);
    }
  }
}

h3,
.h3 {
  font-size: rem($h3Size);
  color:$primary;  
}

h4,
.h4 {
  font-size: rem($h4Size);
  color:$primary;  
  .datenschutzerklaerung &, .dataProtection &{
    margin-bottom:rem(10px);
  }
}

h5,
.h5 {
  font-size: rem($h5Size);
  .datenschutzerklaerung &, .dataProtection &{
    margin-bottom:rem(10px);
  }
}

h6,
.h6 {
  font-size: rem($h6Size);
  .datenschutzerklaerung &, .dataProtection &{
    margin-bottom:rem(10px);
  }
} 

/**
 * Links
 */
a {
  color: $secondary;
  word-wrap: break-word;
  text-decoration:underline;
  .javascript &{
     @extend %animatedTransform; 
  }
  &:hover, &:focus, &:active {
    color: $secondary;
    text-decoration:none;
  } 

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: none;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: #d6d6d6; 
  border: none;
  clear: both;
  height: rem(1px);
  margin: 1rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(20px);
      margin-bottom: rem(20px);
      position:relative;
      strong{
        display:block; 
      }
      &:before{
        content:'';
        position: absolute;
        left:0;
        top:rem(5px);
        background:$secondary;
        height:calc(100% - 10px);
        width:rem(6px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: rem(24px); 
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}