// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $primary;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $primary;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $primary !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $primary;
	}

	fieldset {
		border: none;
		margin:0 0 rem(24px) 0; 
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;

		}
	}

	small {
		line-height: 1rem;
		display: inline-block;
		&.daten{
			margin-bottom:rem(15px); 
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 3.5rem;
		padding-top: 0.9rem;
		
		$inputgroup-count: 3;

		&[name="Nachricht"], &[name="Message"] {
			min-height: 3.5rem * $inputgroup-count - 4rem;
			margin-bottom:rem(20px); 
		} 
	}

	[type="checkbox"] {
		display: inline-block;
		margin: 0 rem(15px) 0 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);
		flex-shrink: 0;

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		margin: 0 rem(15px) 0 0;
        width: rem(15px);
		min-height: auto;
		flex-shrink: 0;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 95%;
    }

    div {
        &.checkbox, &.radio {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                @extend .marginBottom;
            }
            
            label {
                padding-top:0;
                padding-bottom:0;

                &:not(:last-of-type) {
                    margin-right: 2rem;
                }
            }
        }
    }
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}