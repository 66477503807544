// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $light;
	@include breakpoint(large, max) {
		background: #6fa5d5;
	}
	&:before{
		@include breakpoint(large) {
			width:100%; 
		}
	}
}
%buttonActive {
	// Link Active
	color: $light;
	font-weight:700; 
	@include breakpoint(large, max) {
		background: #6fa5d5;
	}
	&:before{
		@include breakpoint(large) {
			width:100%; 
		}
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $secondary;
	z-index: 1001;
	.javascript &{
		transition: all 0.3s ease-in-out;
	}
	.col{
		padding:0; 
	}
	.navRowCont{
		@include breakpoint(large, max) {
			width:100%;
			height:100%; 
			padding-bottom:rem(100px); 
			display:block;
		}
	} 
	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include breakpoint(large, max) {
						border-bottom: 1px solid #6fa5d5; 
						&:last-child{
							border-bottom:none; 
						}
					}
					a, span{
						color:$light; 
					}
					@include hasSub {
						span{
							color:$light; 
							&:after{
							  color:$light; 
							}
						}
						@include sub {
							background: $secondary;
							padding-bottom:rem(100px); 
							.javascript &{
								transition: all 0.3s ease-in-out;
							}
							@include breakpoint(large) {
								padding-bottom:0; 
							}
							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						&:before{
							color:$light; 
							position:relative;
							top:rem(4px); 
						}
					}
				}
			}
		}
	}
}
.naviLangCont{
	display:flex; 
	justify-content:flex-end; 
	//align-items:center; 
	.phoneCont{
		display:none;
		@include breakpoint(large) {
			display:block;
		}
		strong{
			display:none;
			@include breakpoint(huge) {
				display:inline;
			} 
		}
		
		.phone {
			background:$light;
			padding:rem(7px) rem(10px); 
			//margin-right:rem(28px);
			display:block; 
			font-size: rem(14px);
			@include breakpoint(giant) {
				font-size: rem(16px); 
				padding:rem(7px) rem(15px); 
			}
		}
	}
	.languagesWrapper{
		padding:rem(20px) 0;
		width:100%; 
		@include breakpoint(large) {
			padding:0; 
			width:auto; 
		}
		ul{
			display:flex; 
			align-items:center;
			position:relative;
			justify-content:center;
			border:1px solid #6fa5d5;
			min-height:rem(38px); 
			@include breakpoint(large, max) {
				max-width:rem(100px);
				margin:0 auto; 
			}
			@include breakpoint(large) {
				justify-content:flex-end;
				margin:0; 
			}
			&:before{
				content:'';
				position:absolute;
				left:50%;
				top:50%;
				height:rem(19px);
				width:1px; 
				transform:translate(-50%,-50%);
				background:#6fa5d5;
			}
			li{
				margin:0 rem(15px); 
				&:hover, &:focus, &:active{
					a{
						&:before{
							width:100%;	
						}
					}
				}
				&.active{
					
					a{
						font-weight:700;
					} 
				}
				a{
					color:$light; 
					text-transform: uppercase; 
					text-decoration: none; 
					position:relative;
					display:block; 
					line-height:rem(16px); 
					&:before{
						content:'';
						position:absolute;
						bottom:rem(-2px);
						width:0;
						height:1px;
						background:$light;
						.javascript &{
							transition: all 0.3s ease-in-out;
						}
					}
				}
			}
		}
	}
} 
// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		padding:rem(16px) 0; 
		.col{
			padding:0 rem($baseGap); 
		}
		nav {
			ul {
				&.navi {
					justify-content:flex-start;
					li {
						margin-right: rem(20px);
						@include breakpoint(giant) {
							margin-right: rem(40px);
						}
						&.impressum, &.datenschutzerklaerung, &.legalNotice, &.dataProtection {
							display:none; 
						}
						&.standort, &.directions { 
							margin-right: 0;
						}
						a, span{
							padding:0 !important;
							position:relative;
							&:before{
								@include breakpoint(large) {
									content:'';
									position:absolute;
									bottom:0;
									width:0;
									height:1px;
									background:$light;
									.javascript &{
										transition: all 0.3s ease-in-out;
									}
								}
							}
						}
						&.hasSub {
							span{
								display:none; 
							}
							.sub {
								opacity: 1;
								visibility: visible;
								display: flex;
								justify-content: space-between;
								position: relative;
								top: auto !important;
								background:$secondary;
								li{
									&.produkte, &.products {
										margin-right:0; 
									}
								}
							}
						}
						&.navBack {
							display:none; 
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $secondary;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	} 
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAddCont{
	background:$secondary;
	padding:0 0 rem(40px); 
	@include breakpoint(tiny) {
		padding:0; 
	}
	@include breakpoint(medium) {
		padding:rem(40px) 0; 
	}
	.col{
		padding:0;
		@include breakpoint(medium) {
			padding: 0 rem($baseGap); 
		}
	}
	.naviAdd {
		@include breakpoint(medium) {
			display: flex;
			justify-content: flex-start;
		}

		li {
			@include breakpoint(medium, max) {
				border-bottom: 1px solid #6fa5d5; 
				text-align:center; 
				&:last-child{
					border-bottom:none; 
				}
			}
			@include breakpoint(medium) {
				margin-right: rem(38px);
				&:last-child{
					margin-right:0; 
				}
			}
			&:hover, &:focus, &:active{
				a{
					@include breakpoint(medium, max) {
						background: #6fa5d5;
					}
					&:before{
						@include breakpoint(medium) {
							width:100%;
						}
					}
				}
			}
			&.active{
				a{
					font-weight:700; 
					@include breakpoint(medium, max) {
						background: #6fa5d5;
					}
					&:before{
						@include breakpoint(medium) {
							width:100%;
						}
					}
				}
			}
			a, span{
				display:block;
				position:relative;
				color:$light;
				text-decoration:none;  
				&:before{
					@include breakpoint(medium) {
						content:'';
						position:absolute;
						bottom:0;
						width:0;
						height:1px;
						background:$light;
						.javascript &{
							transition: all 0.3s ease-in-out;
						}
					}
				}
				@include breakpoint(medium, max) {
					padding:rem(20px) 0;
				}
			}
		}
	}
}
