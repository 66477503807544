// Use this for default Icomoon

@include fontFaceV2(
  $fontName: "icomoon",
  $fileName: "icomoon",
  $types: (woff, ttf, svg, eot),
  $style: normal,
 );


@include fontFaceV2(
  $fontName: "Open Sans", 
  $fileName: "open-sans-v18-latin", 
  $weights: ("300", "300|italic", "400", "400|italic", "700", "700|italic"), 
  $types: (woff, woff2),
);