noscript {
	margin-top: rem(60px);
	display: block;

	input#noticebar {
		display: none;

		&:checked {
			~ div {
				opacity: 1;
				visibility: visible;
			}

			~ label {
				background: $light;
				color: $alert;

				@extend .icon-times;
			}
		}
	}

	label {
		@include hideText();
		background: $alert;
		bottom: rem(10px);
		color: $light;
		font-size: rem(25px);
		height: rem(45px);
		opacity: 0.9;
		position: fixed;
		right: rem(10px);
		text-decoration: none;
		width: rem(45px);
		z-index: 99999999 !important;

		&:before {
			@extend .icon-exclamation;
			font-family: $iconFont;
			display: inline-block;
			left: 50%;
			position: absolute;
			text-indent: 0;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			cursor: pointer;
			background: darken($alert, 5%);
		}

		+ div {
			opacity: 0;
			visibility: hidden;
			height: 100vh;
		}
	}
}

noscript label + div,
.noticeBar {
	background: $alert;
	color: $light;
	display: flex;
	justify-content: space-around;
	left: 0;
	padding: rem(20px);
	position: fixed;
	text-align: center;
	top: 0;
	transition: 0.3s;
	width: 100%;
	z-index: 9999999 !important;

	span {
		align-self: center;
		font-size: rem(22px);
		line-height: rem(30px);
		margin: 0;
	}

	a {
		color: $light;

		&:hover {
			color: $light;
			text-decoration: none;
		}
	}
}

noscript div:first-child {
	display: none;
}

.breadcrumb li {
	list-style: none;
	float: left;

	&:before {
		content: "❯";
		padding: 0 1rem;
		font-size:60%;
	}

	&:first-child + li:before {
		content: " ";
		padding: 0 0.2rem;
	}
	&:first-child:before {
		content: "";
		padding: 0;
	}
}

/**
 * Debug mode
 */
%debug {
	height: 100%;
	overflow-y: scroll;
	background: $light;
	padding: 1%;
}

#log_hider {
	cursor: pointer;
	z-index: 999999999;
}

#show_console {
	display: none;

	&:checked + #log_container {
		top: 0;
	}
}

#log_container {
	height: 100%;
	width: 100%;
	position: fixed;
	top: -100%;
	left: 0;
	color: $dark;
	background: darken($light, 5%);
	padding: 2%;
	transition: all 0.5s ease-in-out;
	box-shadow: inset 0 0 rem(1px) rem(1px) rgba($alert, 0.5);
	z-index: 99999999;

	pre {
		@extend %debug;
	}

	.checkupXYZ {
		@extend %debug;
	}

	&:target {
		top: 0;
	}

	&.show {
		top: 0;
	}

	ul {
		list-style: inside square;

		ul {
			margin-left: 2rem;
		}

		h3 {
			display: inline-block;
		}

		li {
			padding: 0.5rem 0;
			border-bottom: rem(1px) solid darken($light, 5%);

			&:last-child {
				border: none;
			}
		}
	}

	h1 {
		text-align: center;
		padding-top: 2rem;
	}

	h2 {
		padding-bottom: 0.5rem;
		border-bottom: rem(1px) solid $medium;
		text-align: center;
		font-weight: normal;
		margin-top: 4rem;
	}

	h3 {
		font-weight: normal;
	}

	.checkup_pages {
		li {
			padding: 1rem;
		}

		.sub {
			margin-left: 2rem;
		}
	}

	> p {
		margin-bottom: 4rem;
	}
}

.checkup_pages > li:nth-child(odd) {
	background: #f4f4f4;
}

.checkupXYZ {
	width: rem(960px);
	margin: 0 auto;
	resize: horizontal;
}

.informatizer {
	background: #8dd45c;
	padding: 1%;
	position: fixed;
	top: 2%;
	right: 2%;
	display: block;
	color: $light;
	transition: all 0.5s ease-in-out;
	text-decoration: none;

	&:before {
		@extend .icon-exclamation;
		margin-right: 1rem;
		font-size: em(24px);
		line-height: 1rem;
	}

	a {
		color: $light;
	}
}

.btnFocus {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scale(0);
	padding: 2.6em;
	background: rgba($secondary, 0.9);
	color: $light !important;
	font-size: rem(24px);
	z-index: 2000;
	transition: transform 300ms;
	text-decoration: none;
	opacity:0;

	&:focus {
		transform: translate(-50%, -50%) scale(1);
		opacity:1;
	}
}

body.sitemap {
	display: flex;
	padding:2rem;

	nav {
		width: auto;
		margin:0 auto;
		.sitemap {
			ul {
				padding-left: 20px;
			}
		}
	}
}

.backToTop {
	@extend %animatedTransform;
	position: fixed;
	bottom: rem($baseGap);
	right: rem($baseGap);
	z-index: 800;
	font-size: 1rem;
	width: 3em;
	height: 3em;
	line-height: 3em;
	background-color: $medium;
	color: $primary;
	text-decoration: none;
	text-align: center;
	
	&:hover, &:active, &:visited {
		color: $light;
		background:$primary;
	}
	body:not(.scrollingUp) & {
		opacity: 0;
		visibility: hidden;
	}

	span {
		display: none;
	}
}